type Props = { className?: string };

export const Icon404 = ({ className }: Props) => {
  return (
    <svg
      width="383"
      height="125"
      viewBox="0 0 383 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="presentation"
    >
      <path
        d="M11.7172 122V87.2H-65.5628V61.28C-64.1228 58.72 -62.2828 55.6 -60.0428 51.92C-57.6428 48.24 -54.8428 44 -51.6428 39.2L-0.042809 -36.4H48.1972V58.88H72.4372V87.2H48.1972V122H11.7172ZM-32.4428 58.88H12.1972V10.88C12.1972 7.68 12.2772 4.4 12.4372 1.04C12.5972 -2.48 12.7572 -6.00001 12.9172 -9.52001H12.4372C10.8372 -6.16 8.91719 -2.64 6.67719 1.04C4.59719 4.56 2.59719 7.75999 0.677189 10.64L-27.4028 51.68C-28.2028 52.96 -29.0028 54.16 -29.8028 55.28C-30.6028 56.4 -31.4828 57.6 -32.4428 58.88ZM156.553 124.4C134.953 124.4 118.553 117.52 107.353 103.76C96.3134 90 90.7934 69.68 90.7934 42.8C90.7934 15.92 96.3134 -4.40001 107.353 -18.16C118.553 -31.92 134.953 -38.8 156.553 -38.8C178.153 -38.8 194.473 -31.92 205.513 -18.16C216.713 -4.40001 222.313 15.92 222.313 42.8C222.313 69.68 216.713 90 205.513 103.76C194.473 117.52 178.153 124.4 156.553 124.4ZM156.553 92.24C162.473 92.24 167.273 90.64 170.953 87.44C174.633 84.08 177.273 78.8 178.873 71.6C180.633 64.24 181.513 54.64 181.513 42.8C181.513 30.96 180.633 21.44 178.873 14.24C177.273 6.87999 174.633 1.59999 170.953 -1.60001C167.273 -4.96001 162.473 -6.64 156.553 -6.64C150.793 -6.64 145.993 -4.96001 142.153 -1.60001C138.473 1.59999 135.753 6.87999 133.993 14.24C132.393 21.44 131.593 30.96 131.593 42.8C131.593 54.64 132.393 64.24 133.993 71.6C135.753 78.8 138.473 84.08 142.153 87.44C145.993 90.64 150.793 92.24 156.553 92.24ZM321.327 122V87.2H244.047V61.28C245.487 58.72 247.327 55.6 249.567 51.92C251.967 48.24 254.767 44 257.967 39.2L309.567 -36.4H357.807V58.88H382.047V87.2H357.807V122H321.327ZM277.167 58.88H321.807V10.88C321.807 7.68 321.887 4.4 322.047 1.04C322.207 -2.48 322.367 -6.00001 322.527 -9.52001H322.047C320.447 -6.16 318.527 -2.64 316.287 1.04C314.207 4.56 312.207 7.75999 310.287 10.64L282.207 51.68C281.407 52.96 280.607 54.16 279.807 55.28C279.007 56.4 278.127 57.6 277.167 58.88Z"
        fill="#D5F0EE"
      />
    </svg>
  );
};
